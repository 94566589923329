import React from 'react';
import { Routings } from '@/common/routings';
import { WithdrawalTextContainer, WithdrawalWrapper } from '@/components/organisms/Buyer/Account/StyledComponents';
import { SecondaryButton } from '@/components/atoms/Button';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { SpBr } from '@/components/atoms/CommonStyles';

const ToTopButton = styled(SecondaryButton)`
  margin-top: 40px;
`;

const Complete = () => {
  return (
    <WithdrawalWrapper>
      <WithdrawalTextContainer>
        退会のお手続きが完了いたしました。
        <br />
        この度はKUSABIをご利用いただき、
        <SpBr />
        誠にありがとうございました。
      </WithdrawalTextContainer>
      <ToTopButton onClick={() => navigate(Routings.buyerTop.location)}>トップ画面へ</ToTopButton>
    </WithdrawalWrapper>
  );
};

export default Complete;
