import React from 'react';
import BuyerDeleteTemplate from '@/components/organisms/Buyer/Account/Delete/BuyerDeleteTemplate';
import Complete from '@/components/organisms/Buyer/Account/Delete/Complete';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';
import { Routings } from '@/common/routings';
import { useDirectAccess } from '@/common/hooks';

const BuyerDeletePage = () => {
  const isDirectAccess = useDirectAccess(Routings.buyerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <BuyerHeader displayPattern="inquiry" />
      <BuyerDeleteTemplate>
        <Complete />
      </BuyerDeleteTemplate>
      <BuyerFooter />
    </Wrapper>
  );
};

export default BuyerDeletePage;
